import { FC } from 'react';
import { SVGIconType } from './types';
import { css } from '@bts-web/utils-style-engine';

const ExclamationTriangleIcon: FC<SVGIconType> = ({ color, size }) => {
  const colorClasses = css({
    color: color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <div
      className={colorClasses}
      style={{ width: size, height: 'auto' }}
      data-testid="exclamation-triangle-icon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
      >
        <path
          fill="currentcolor"
          d="M16 12.55a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M14.039 7.206c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H7.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z"
        ></path>
      </svg>
    </div>
  );
};

export { ExclamationTriangleIcon };
