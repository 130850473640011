import { ErrorPage } from '../index';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';

const ErrorServerPage = async () => {
  await updateServerLanguage();

  const { t } = initServerTranslation();

  return (
    <ErrorPage
      title={t('internal_error_headline', {
        defaultValue: `We're having internal connection problems`,
      })}
      description={t('internal_error_text', {
        defaultValue: 'Try relaunching the app.',
      })}
    />
  );
};

export { ErrorServerPage };
