import { ErrorPage } from '../index';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';

const ErrorGenericPage = async () => {
  await updateServerLanguage();

  const { t } = initServerTranslation();

  return (
    <ErrorPage
      title={t('general_error_headline')}
      description={t('general_error_text')}
    />
  );
};

export { ErrorGenericPage };
