import { ErrorPage } from '../index';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';

const ErrorAuthErrorPage = async () => {
  await updateServerLanguage();

  const { t } = initServerTranslation();

  return (
    <ErrorPage
      title={t('app_loading_error_title')}
      description={
        <>
          {t('app_loading_error_subtitle_1')}
          <br />
          {t('app_loading_error_subtitle_2')}
        </>
      }
    />
  );
};

export { ErrorAuthErrorPage };
