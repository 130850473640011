/* RSC */

import { css } from '@bts-web/utils-style-engine';
import { PropsWithChildren, ReactElement } from 'react';
import { ExclamationTriangleIcon } from '../../common/svgIcons/ExclamationTriangleIcon';

interface ErrorPageProps extends PropsWithChildren {
  title: string | ReactElement;
  description: string | ReactElement;
}

const ErrorPage = ({ title, description }: ErrorPageProps) => (
  <div
    data-testid="error-page"
    className={css({
      display: 'flex',
      alignItems: 'center',
      minHeight: '100vh',
      justifyContent: 'center',
      gap: 0,
    })}
  >
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'medium',
        width: '100%',
        maxWidth: 400,
      })}
    >
      <div
        className={css({
          width: 80,
          height: 80,
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'neutrals.fill_quinary',
          borderRadius: 'largeBottomLeft',
        })}
      >
        <ExclamationTriangleIcon size={24} />
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          p: 'small',
          gap: 'extra_small_2',
          textAlign: 'center',
          margin: 'auto',
          mt: 0,
        })}
      >
        <p
          className={css({
            color: 'neutrals.text_primary',
            fontSize: 'headline.small_medium',
            fontWeight: 'headline.small_medium',
            lineHeight: 'headline.small_medium',
          })}
        >
          {title}
        </p>
        <p
          className={css({
            color: 'neutrals.text_secondary',
            fontSize: 'body.medium',
            fontWeight: 'body.medium',
            lineHeight: 'body.medium',
          })}
        >
          {description}
        </p>
      </div>
    </div>
  </div>
);

export { ErrorPage };
